*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --Deep-green: #164b2e;
  --Light-green: #2d995f;
  --Gray: #f2f3f3;
  --White: #ffffff;
  --Black: #343a40;
}
.fetchedTxt {
  font-size: 16px;
  font-weight: 500;
}

.analysis {
  padding: 18px 100px 14px 32px;
  background-color: var(--Deep-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  /* color: var(--White); */
}
.analysisCont h5,
.analysisCont p {
  color: var(--White);
  margin: 0;
}

.btnAt {
  border: 0;
  cursor: pointer;
}
.perceCont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding-top: 10px;
  /* color: var(--White); */
}

.btnStyle {
  padding: 20px 0 0 0;
  margin-bottom: 20px;
}

.btndivStyle {
  display: flex;
  gap: 20px;
}

.percent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* color: var(--White); */
}

.finishedbodyCont {
  width: 100%;
  padding: 0 5rem;
  /* padding-right: 4rem; */
  /* color: #0a4e3e; */
}
.mainTable table td {
  /* padding: 15px 0 0 5px; */
  align-items: center;
  padding: 0 0 0 5px;
  /* padding-left: 10px; */

  /* display: none; */
}
.productText1 {
  font-weight: 700;
}

.endded {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.topPadding {
  width: 100%;
  padding-top: 1rem;
}
.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSecCont h3 {
  color: #343a40;
  font-size: 20px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 30px; */
}
.loandethead .formIntBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}

.laondetheadTopCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-top: 1rem;
}

.laondetheadTopadfCards {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 25px;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.laondetheadTopCards121 {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* gap: 25px; */
  margin-top: 1rem;
}

.laondetheadTopCards .card {
  border: 1px solid var(--Light-green);
  padding: 8px 60px 8px 60px;
  text-align: left;
}
.laondetheadTopCards .card h4 {
  color: var(--Light-green);
  font-weight: 800;
  margin-top: 2px;
  text-align: right;
  /* font-size:20px; */
}
.laondetheadTopCards .card p {
  margin-bottom: 0;
}

.formIntBtn .btn1 {
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn1:hover {
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}
.formIntBtn .btn2 {
  font-size: 14px;
}

.overBtn {
  display: flex;
  gap: 10px;
  align-items: center;
}
/* .loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Light-green);
  font-size: 16px;
  gap: 5px;
} */
.loandethead button:hover {
  background-color: var(--Light-green);
  color: var(--White);
}

.loanText {
  font-size: 15px;
}
.loanText1 {
  font-size: 15px;
  margin-left: 20px;
}
.loanText2 {
  font-size: 15px;
  margin-left: 25px;
}

.fetchText {
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount {
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText {
  font-size: 18px;
  font-weight: 700;
  color: rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loandgrantcards {
  margin-bottom: 30px;
  margin-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.loanContainer {
  margin-top: 15px;
  overflow-x: auto;
}

.loanTable {
  color: black;
  background-color: rgba(233, 236, 239, 1);
  height: 50px;
  font-weight: normal;
  white-space: nowrap;
  /* padding: 30px; */
  text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th {
  background-color: var(--Light-green);
  color: white;
  /* font-size: 14px; */
}
.loanResponsive table td {
  font-size: 14px;
  border: none !important;
}
.loanResponsive table > td,
th {
  /* border: none; */
  /* font-size: 12px; */
}
.loanResponsive .edit {
  color: var(--Light-green);
  cursor: pointer;
  text-align: center;
}
.loanResponsive .accrodBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Light-green);
}
.accrodBtns p {
  margin: 0;
  font-size: 18px;
}
.loanResponsive .accrodBtns .prev {
  border: 1px solid #2d995f;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
}
.loanResponsive .accrodBtns .next {
  padding: 5px 20px;
}
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: "inter";
  color: #2d995f;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color: rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan {
  width: 300px;
  height: 55px;
  background-color: #2d995f;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover {
  background-color: #46a874;
}

.continueReg {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.regContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  /* gap: 30px; */
}
.sideNav {
  background-color: var(--Deep-green);
  padding: 37px 0 0 21px;
  height: 1800px;
  max-width: 339px;
}
.regMenu {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.regMenu a {
  text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p {
  color: var(--White);
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.regMenu .active {
  background: linear-gradient9(#2d997f) (#2d997f) 0;
}
.formSection {
  width: 100%;
  /* padding-top: 3rem; */
  padding-left: 1rem;
  /* background-color: #4b9483; */
  padding-right: 3rem;
  margin-top:  2rem;
  /* background-color: white; */
}

.formSection p {
  font-size: 14px;
  font-family: "inter";
}

.formSection h1 {
  color: var(--Light-green);
  font-size: 28px;
  font-weight: 700;
}

.formSectionHeader {
  width: 100%;
  padding-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formSectionHeader h3 {
  color: var(--Light-green);
  font-size: 20px;
  font-weight: 700;
}
.formSectionHeader h4 {
  font-weight: 700;
}

.serchsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 79px;
}

.payText {
  color: #343a40;
  font-size: 18px;
  font-weight: 700;
}

.tableCont {
  width: 100%;
  display: flex;
  gap: 5px;
  /* border: 1px solid var(--Light-green); */
}
.table {
  border: 1px solid var(--Light-green);
  flex: 1;
}

.tableHeadh {
  font-size: 14px;
  font-weight: 600;
  color: #343a40;
}

.bodyH {
  color: #343a40;
}

.addText {
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 15px;
}

.flakes {
  width: 47px;
  height: 47px;
}

.btnSpan {
  display: flex;
  align-items: center;
  gap: 34px;
}

.minusBtn {
  border: 0.5px solid #2d995f;
  border-radius: 4px;
  padding: 8px;
  background-color: #ffffff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}
.plusBtn {
  border: 0.5px solid #2d995f;
  border-radius: 4px;
  padding: 8px;
  background-color: #ffffff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}

.btnBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;
  margin-top: 30px;
}

.printBtn {
  background-color: #2d995f;
  border-radius: 4px;
  padding: 12px 90px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  min-width: 30%;
}
.printBtn1:disabled {
  background-color: #4fbe83;
  border-radius: 4px;
  padding: 12px 90px;
  color: #ffffff;
  font-size: 14px;
  cursor: not-allowed;
  font-weight: 400;
  min-width: 30%;
  margin-top: 10px;
}

.printBtn1 {
  background-color: #2d995f;
  border-radius: 4px;
  padding: 12px 90px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  min-width: 30%;
  margin-top: 10px;
}

.clearBtn {
  border: 1px solid #2d995f;
  margin-top: 15px;
  border-radius: 4px;
  padding: 12px 90px;
  color: #2d995f;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  min-width: 30%;
}

.trsDiv{
  display:flex;
  align-items:center;
  justify-content: space-between;

}

.trash {
  width: 17px;
  height: 17px;
}

.sub {
  min-width: 372px;
  border: 1px solid #2d995f;
  padding: 19px 23px;
}

.sTal {
  font-size: 14px;
  font-weight: 700;
  color: #343a40;
}
.ites {
  display: flex;
  justify-content: space-between;
}

.name {
  font-size: 14px;
  font-weight: 400;
  color: #343a40;
}

.ttl {
  font-size: 14px;
  font-weight: 700;
  color: #343a40;
}

.itess {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
}
.formSecCont h3 {
  color: #343a40;
  font-size: 20px;
  font-weight: 700;
}
.mainform {
  width: 100%;
  /* background-color: #F8FFFB; */
  /* border-radius: 12px; */
  margin-top: 10rem;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
}
.actionsBtns button {
  border-radius: 0;
  font-size: 14px;
}
.diffbtn {
  width: 145px !important;
  height: 35px;
}

@media (max-width: 800px) {
  .mainform {
    padding: 0;
  }
  .mainform h3 {
    text-align: center;
  }
  .mainform p {
    text-align: center;
  }
  .finishedbodyCont {
    padding-left: 0;
    padding-right: 0;
  }
  .formSecCont {
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
  }
  .formSecCont h3 {
    color: #343a40;
    font-size: 20px;
    font-weight: lighter;
  }

  .loandethead {
    flex-direction: column;
    gap: 20px;
    /* margin-left: 30px; */
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }

  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan {
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }

  .continueReg {
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
    /* text-align: center; */
  }
}

@media (max-width: 992px) {
  .btnStyle {
    display: flex;
    flex-direction: column;
  }

  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }

  .finishedbodyCont {
    padding-top: 110px;
  }

  .btndivStyle {
    display: inline-block;
    gap: 0px;
  }

  .show {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .formobile {
    margin-bottom: 10px;
    /* align-items: center; */
  }
}

.btndivStyle {
  display: flex;
  gap: 20px;
}

.btnStyle {
  padding: 0px 0 0 0;
  margin-bottom: 20px;
}

.finishedbodyCont {
  width: 100%;
  padding: 6rem 2rem 5rem 2rem;
  background-color: #fffef6;
}

@media (max-width: 800px) {
  .finishedbodyCont {
    padding-left: 0;
    padding-right: 0;
  }
  .formSecCont {
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
  }
  .formSecCont h3 {
    color: #343a40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }

  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan {
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }

  .continueReg {
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
    /* text-align: center; */
  }

  .btnStyle {
    display: flex;
    flex-direction: column;
  }

  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }

  .finishedbodyCont {
    padding-top: 110px;
  }

  .btndivStyle {
    display: inline-block;
    gap: 0px;
  }

  .show {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .newWidth {
    padding: 0;
  }
}
