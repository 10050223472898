.invoiceContainer {
  color: #333;
  width: 700px;
  margin: 0 auto;
  /* border: 1px solid #e0e0e0; */
  padding: 20px;
  background-color: white;
}

.promixHead {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.companyInfo12 {
  display: flex;
  flex-direction: row;
  justify-content: right;
  justify-items: right;
  gap: 150px;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.logo {
  width: 50px;
  /* height: 50px; */
}

.logoStyle {
  width: 100%;
  object-fit: cover;
}

.logoStyle12 {
  width: 15%;
  object-fit: cover;
}

.companyT p {
  margin: 0;
  font-weight: 400;
  color: #000000;
  line-height: 1.5;
  text-align: right;
  font-size: 16px;
}

.promixHead {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-align: right;
}

.title {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
  color: #000000;
  font-weight: 700;
}

.billTo {
  margin-top: 10px;
  font-size: 12px;
  color: 10px;
  font-weight: 400;
  color: #000000;
}

.billText p {
  font-size: 12px;
  color: 10px;
  font-weight: 400;
  color: #000000;
}

.billT {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.billDiv {
  display: flex;
  align-items: first baseline;
  justify-content: space-between;
}

.dateIssued {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 5px;
  color: #000000;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th {
  background-color: #2d995f;
  color: white;
  padding: 12px;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
}

.table td {
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
}

.totals {
  text-align: right;
  margin-top: 20px;
  padding-right: 10px;
}

.tableSy tr:nth-child(even) {
  background-color: #ace7c74d;
}

.totals p {
  margin: 4px 0;
  font-size: 14px;
  font-weight: 400;
  color: #343a40;
}

.totals h3 {
  font-size: 18px;
  font-weight: bold;
}

.notes {
  margin-top: 30px;
}

.notes h4 {
  font-size: 16px;
  font-weight: 400;
  color: #343a40;
}

.notes p {
  font-size: 14px;
  font-size: 400;
  color: #343a40;
  line-height: 1.5;
}
