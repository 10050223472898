*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background-color: #f0f0f0; */
}

.a4 {
  width: 29.7cm;
  min-height: 21cm;
  margin: 0 auto; /* Set margin-top to 0 */
  padding: 1cm;
  background-color: white;
  padding: 0.5in;
  page-break-after: always; 
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  header,
  footer {
    display: none; /* Hide header and footer if they cause extra space */
  }
  .headerTop {
    margin-top: -60px;
  }
  /* Adjust any other elements causing the extra space */
}

.report {
  max-width: 800px;
  margin: 30px auto;
  background-color: white;
  /* border: 1px solid #000; */
  padding: 20px;
  padding-bottom: 55px;
  /* padding-top: -50px; */
  position: relative;
  overflow: hidden;
}

.headerTop {
  font-size: 20px;
  text-align: left;
  /* padding-top: 20px; */
  margin-bottom: 0;
  font-weight: 600;
  margin: 0;
}

.headerTwo {
  /* font-weight: 800; */
  text-transform: uppercase;
  text-wrap: wrap;
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
}
.headerTwoo {
  /* font-weight: 800; */
  text-transform: uppercase;
  text-wrap: wrap;
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tableDivF {
  display: table;
  border-collapse: seperate;
  border-spacing: 10px;
  margin-top: 30px;
}

.underlinHeader {
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
}

.noteDiv {
  font-weight: normal;
  text-align: center;
  align-items: center;
}

.tableDate {
  text-align: center;
  text-wrap: wrap;
  max-width: 60px !important;
}

.assetDigit {
  align-items: center;
  text-align: center;
}
.notesNumber {
  align-items: center;
  text-align: center;
}

.notUnderlineHeader {
  font-weight: 700;
  font-size: 12px;
  /* margin-bottom: 30px; */
}

.divthPad {
  padding-bottom: 15px;
  /* margin: 100px; */
  /* background-color: blue; */
}

.btmPrg {
  font-weight: 600;
  /* margin-bottom: 30px; */
  font-size: 14px;
  max-width: 600px;
}

.sigNatures {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  font-weight: 500;
}

.sigNatures > span {
  border-top: 1px solid black;
}

.borderAdd {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.borderS {
  text-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.backgroungThird {
  background-color: rgb(236, 233, 233);
}

.backgroungFour {
  background-color: rgb(204, 201, 201);
}

.spaceLeft {
  padding-left: 30px;
}

.addPadd {
  padding: 10px;
  /* border: 2px solid black; */
}

.tdWithMargin {
  margin-right: 10px; /* Adjust as needed */
}
