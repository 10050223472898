.card{
  background-color:white;
  width: 100%;
  max-width: 70%;
 
  /* margin: auto; */
  position: absolute;
  top: 240px;
  left: 170px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* height: 70%; */
  border-radius: 20px;
  box-shadow: 0px 4px 30px 20px #F2F2F221;
  padding-top: 40px;
  margin-bottom: 90px;
}

.pic{
  height:300px;
  width: 100%;
  /* object-fit: contain; */
}

/* .cardmain{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
} */

.header{
    /* display: flex; */
    width: 100%; 
     height: 100vh;
    background-color: #F8FBFF;
    
    /* flex-direction: column; */
}
/* @media (max-width: 991.98px) {

}

.pic {
  width: 100%;
  height: 250px;
  position: relative;
}

@media (max-width: 991.98px) {


cardmain {
  width: 300px;
  margin: 0 auto;
}

.snupbtndfa {
  background-color: #ffffff;
  color: #2D995F;
  width: 70%;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  padding: 10px;
  border: 1px solid #2D995F;
}

.footer {
} */
