*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* colors */
:root {
  --Deep-green: #164b2e;
  --Light-green: #2d995f;
  --Gray: #f2f3f3;
  --White: #ffffff;
  --Black: #343a40;
  --blackGray: #6c757d;
  /* --Background:rgba(172, 231, 199, 0.05); */
}
.formSection {
  width: 100%;
  /* padding-top: 3rem; */
  padding-left: 3rem;
  /* background-color: #4b9483; */
  padding-right: 3rem;
  margin-top: 6rem;
}

.logoStyle {
  /* height: 100px; */
  width: 100px;
  object-fit: contain;
}

.stinfo {
  /* padding-left: 20px; */
}

.active {
  /* Your active tab styling */
  transform: scale(1.05);
  opacity: 1;
}

.topSty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.divS {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
  justify-content: center;
  padding: 20px 50px 10px;
  width: 80%;
  margin: 0 auto;
}

.divInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100px;
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for scaling and shadow */
.divInner:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for the icon image */
.divInner > img {
  width: 54px;
  height: 54px;
  margin-bottom: 8px; /* Adds space between icon and text */
}

/* Styling for the label text */
.divInner > p {
  font-size: 12px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0;
}

.payText {
  color: #343a40;
  font-size: 18px;
  font-weight: 700;
}

.wlc {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
}

.userN {
  color: #2d995f;
  font-weight: 700;
  font-size: 20px;
  margin: 0px;
}

.srh {
  position: absolute;
  top: 6px;
  left: 15px;
  z-index: 11;
}

.formSectionHeader {
  width: 100%;
  padding-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSection p {
  font-size: 14px;
  font-family: "inter";
}

.trsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formSectionHeader h3 {
  color: var(--Light-green);
  font-size: 20px;
  font-weight: 700;
}
.formSectionHeader h4 {
  font-weight: 700;
}
.InputSearch {
  position: relative;
  z-index: 1; /* Ensures it’s above other elements */
  pointer-events: auto; /* Allows click events */
  width: 576px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #adb5bd;
  padding-left: 45px;
}

.minusBtn {
  border: 0.5px solid #2d995f;
  border-radius: 4px;
  padding: 8px;
  background-color: #ffffff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}
.plusBtn {
  border: 0.5px solid #2d995f;
  border-radius: 4px;
  padding: 8px;
  background-color: #ffffff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}

.btnBtn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;
  margin-top: 30px;
  padding-right: 25px;
}

.printBtn {
  background-color: #2d995f;
  border-radius: 4px;
  padding: 12px 90px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  min-width: 30%;
}

.clearBtn {
  border: 1px solid #2d995f;
  margin-top: 20px;
  border-radius: 4px;
  padding: 8px 90px;
  color: #2d995f;
  cursor: pointer;
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  min-width: 30%;
  /* margin-right: 30px; */

}

.addText {
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 15px;
}

.tableHeadh {
  font-size: 14px;
  font-weight: 600;
  color: #343a40;
}

.bodyH {
  color: #343a40;
}

.btnSpan {
  display: flex;
  align-items: center;
  gap: 34px;
}

.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  border-radius: 12px;
  width: 100%;
}

.serchsection {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 4px;
}
.search {
  border: 1px solid #adb5bd;
  color: #adb5bd;
  width: 376px;
  padding: 5px 29px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 9999999;
}

.mainTabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tableCont {
  width: 98%;
  display: flex;
  gap: 5px;
  /* border: 1px solid var(--Light-green); */
}

.ites {
  display: flex;
  justify-content: space-between;
}

.itess {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.name {
  font-size: 14px;
  font-weight: 400;
  color: #343a40;
}

.ttl {
  font-size: 14px;
  font-weight: 700;
  color: #343a40;
}

.table {
  border: 1px solid var(--Light-green);
  flex: 1;
}

.sub {
  min-width: 372px;
  border: 1px solid #2d995f;
  padding: 19px 23px;
}

.sTal {
  font-size: 14px;
  font-weight: 700;
  color: #343a40;
}

.flakes {
  width: 47px;
  height: 47px;
}
.nestle {
  width: 47px;
  height: 47px;
}

.incrmntbtns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
}

.dcrmnt {
  border: 0.5px solid #2d995f;
  color: #6c757d;
  border-radius: 4px;
  padding: 2px 10px;
}
.incrmnt {
  border: 0.5px solid #2d995f;
  color: #6c757d;
  border-radius: 4px;
  padding: 2px 10px;
}

.trash {
  width: 17px;
  height: 17px;
}

.shortcurt {
  border: 1px solid #2d995f;
  width: 845px;
  margin-top: 108px;
}

.prdtxt {
  border-bottom: 1px solid #2d995f;
}

.prods {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
}

.nascotxt {
  padding-left: 15px;
  font-weight: 300;
  font-size: 10px;
  color: #343a40;
  font-family: "inter";
}
.nestletxt {
  padding-left: 15px;
  font-weight: 300;
  font-size: 10px;
  color: #343a40;
  font-family: "inter";
}

.headprodtxt {
  font-size: 12px;
  font-weight: 400;
  color: #343a40;
  margin-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "inter";
}

.nasco {
  display: flex;
  border: 1px solid #adb5bd;
  align-items: center;
  padding: 7px 15px 7px 8px;
  /* border-radius: 5px; */
  margin-left: 10px;
}

.nascoflakes {
  width: 40px;
  height: 40px;
}

.nestlemiloimg {
  width: 41px;
  height: 41px;
}
.nestlemilo {
  display: flex;
  border: 1px solid #adb5bd;
  align-items: center;
  padding: 7px 15px 7px 8px;
  /* border-radius: 5px; */
  margin-left: 10px;
}

.entireprod {
  display: flex;
}

.rcptinvc {
  display: flex;
  margin-top: 25px;
  gap: 20px;
}

.prnttcpt {
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-color: #2d995f;
  width: 323px;
  height: 41px;
  border-radius: 4px;
  font-family: "inter";
}

.clritms {
  border: 1px solid #2d995f;
  color: #2d995f;
  font-size: 14px;
  font-weight: 400;
  width: 323px;
  height: 41px;
  font-family: "inter";
}
