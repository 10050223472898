.divS {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
  /* justify-content: center; */
  padding: 30px 30px 10px;
  width: 98%;
  margin: 10px auto;
}

.formADFADSection {
  margin-bottom: 3rem;
  z-index: -1;
}

.formSectionHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--White);
  z-index: 999;
}

.formSectionHeader h3 {
  color: var(--Light-green);
  font-size: 20px;
  font-weight: 700;
}
.formSectionHeader h4 {
  font-weight: 700;
}

.formSectionHeaderContents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100px; */
  /* z-index: 999; */
}

.horinfodashbrd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  height: 50px;
  padding: 0 0 0 0;
  background-color: #ffffff;
  /* border: 1px solid #CED4DA; */
  z-index: 999;
  /* border-bottom: 1px solid black; */
  /* padding-top: 4rem; */
}

.stinfo {
  padding-left: 20px;
}

.logoStyle {
  /* height: 100px; */
  width: 100px;
  object-fit: contain;
}

.stinfosts {
  font-weight: 700;
  font-size: 12px;
  font-family: "inter";
  color: #6c757d;
  /* margin-top: 10px; */
  /* margin-bottom: -17px; */
}

.usrinfo {
  display: flex;
  /* justify-content: space-between; */
  /* justify-content: flex-end; */
  align-items: center;
  width: 100%;
}

.usrnminf {
  margin-right: -25px;
  text-align: right;
}

.usrnme {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.Dropdowns {
  background-color: transparent !important;
  border: none !important;
  border-radius: 80px !important;
  /* color: black !important; */
  /* padding: 15px; */
  width: 100%;
  min-width: 120px;
}

.avtrdfs {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  /* margin-top: -5px; */
  background-color: #2d995f;
  cursor: pointer;
  /* display: flex; */
  padding: 8px;

  justify-content: center;
  align-items: center;
}

.divInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 30px;
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.divInner:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for the icon image */
.divInner > img {
  width: 25px;
  height: 25px;
  margin-bottom: 8px; /* Adds space between icon and text */
}

/* Styling for the label text */
.divInner > p {
  font-size: 12px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0;
}

.topInfo {
  position: fixed;
  top: 0;
  background-color: white;
  min-width: 100vw;
  z-index: 1;
}

.topSty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logoStyle {
  /* height: 100px; */
  width: 100px;
  object-fit: contain;
}

.wlc {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
}

.userN {
  color: #2d995f;
  font-weight: 700;
  font-size: 20px;
  margin: 0px;
}
