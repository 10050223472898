body {
  background-color: rgba(172, 231, 199, 0.05) !important;

}
*,
::after,
::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* colors */
:root{
  --Deep-green: #164B2E;
  --Light-green: #2D995F; 
  --Gray:#f2f3f3;
  --White: #ffffff;
  --Black: #343A40;
  --Black: #343A40;
  --Background:rgba(172, 231, 199, 0.05);
}


/* AddTeam Styling */
.textMain {
  display: flex;
  gap: 200px;
  padding-left: 300px;
  text-align: right;
  margin: 30px 0 20px 0;
}

.textMainds {
  display: flex;
  gap: 98px;
  margin-bottom: 20px;
  /* margin-left: 50px; */
}

.snupbtnreddfa {
  background-color: #fd0000;
  color: #ffffff;
  width: 100%;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  padding: 10px;
  border: 1px solid #fd0000;
}

.formSection23fa45 {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  background-color: #ACE7C726;
  width: auto;
  height: auto;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
}

.normdfalp {
  /* width: auto; */
  /* height: auto;
  width: 100%; */
  /* background-color: #E9ECEF; */
  /* overflow-y: scroll; */
  text-align: center;
  align-items: center;
  /* align-content: center; */
  /* height: 100vh; */
}

.formSection23fa45 p{
  font-size: 10px;
}

.formSection23fa45 h5{
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  color: #2D995F;

}


/* Registration Complete Styling */
.maincont1234 {
  /* width: 100%; */
  /* display: flex; */
  /* overflow-y: hidden; */
  /* height: 100vh; */
  /* background-color: white; */
}

.sideNavContainer{
  /* background-color: var(--Deep-green); */
  height: 100vh;
  width: 35%;
  padding-left: 50px;
  overflow: hidden;
  /* background-image: url('./TeamMemberSideImg.svg'); */
  background-size: cover;
  /* background-size: contain; */
  /* padding: 31px 64px ; */
  background-repeat: repeat-y;
}

.sideNavContainer h4{
  font-size: 35px;
  color: white;
  font-weight: 800;
  margin-top: 430px;
}

.sideNavContainer p{
  font-size: 20px;
  color: white;
}

.mobileshow {
  height: 880px;
  /* width: 610px; */
}

.formSon2345 {
  text-align: left;
  margin-top: 20px;
    /* border: 1px solid #CED4DA; */
  border-radius: 8px;
  margin: 0 80px 50px 80px;
  padding: 20px 50px 30px 50px;
  margin-top: 30px;
  
}

.formSection{
  /* width: 100%;
  background-color: #E9ECEF;
  overflow-y: scroll;
  text-align: center;
  align-items: center;
  align-content: center;
  height: 100vh; */

}

.normalp{
  /* width: 65%; */
  padding: 80px;
  /* padding-left: 50px;
  padding-right: 200px;
  padding-top: 80px; */
  /* padding-left: 170px; */
  /* background-color: #E9ECEF; */
  /* padding-right: 3rem; */
  overflow-y: hidden;
  text-align: center;
  align-items: center;
  align-content: center;
  height: 100vh;

}
.Imageshow213 {
  height: 180px;
  width: 280px;
}

.Imageshowdfsf213 {
  /* height: 50px;
  width: 130px; */
}

.Imageshowda213 {
  /* height: 180px; */
  width: 400px;
  margin-bottom: 20px;
  margin-top: 20px;

  /* padding: 0; */
}

.snupbtn {
  border: 1px solid #2D995F;
  color: #2D995F;
  background-color: #ffffff;
  width: 250px;
  height: 43px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  padding: 10px;
  border-radius: 4px;
}
.snupbtn1 {
  /* border: 1px solid #2D995F; */
  color: #ffffff;
  background-color: #2D995F;
  width: 250px;
  height: 43px;
  /* margin-top: 15px; */
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  padding: 10px;
  border-radius: 4px;
}

.snupdrgtbtn {
  background-color: #ffffff;
  color: #2D995F;
  /* width: 13%; */
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  padding: 5px;
  /* border: 1px solid #fd0000; */
  display: flex;
  /* margin-left: 50%; */
  /* position: absolute; */
}

.snupdfdfbtn {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  padding: 5px;
  border: 1px solid #fd0000;
  display: flex;
  /* margin-left: 50%; */
  /* position: absolute; */
}

.buttondivddsadf {
  width: 100%;
  /* background-color: red; */
  height: 40px;
  /* justify-content: left; */
  /* align-items: left; */
  text-align: left;
  display: flex;
  justify-content: space-between;
  /* align-content: left; */
  /* justify-items: left; */
}

.snupbtndfa {
  /* background-color: #ffffff; */
  color: #6C757D;
  /* width: 100%; */
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter';
  /* padding: 10px; */
  /* border: 1px solid #2D995F; */
}

.bottomF{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.closeBtn{
  color: #343A40;
  margin-top: 10px;
}
.closeBtn1{
  color: #343A40;
  margin-top: 10px;
}

.formSection2345 {
  padding: 20px 50px 30px 50px;
  /* border: 1px solid #CED4DA; */
  /* border-radius: 8px; */
  text-align: center;
  /* width: auto;
  height: auto; */
  /* overflow-y: hidden; */
  /* margin: 0 80px 50px 80px; */
}

.formSection2345 h4 {
  font-weight: 800;
  font-size: 25px;
}

.formSection2345 p {
margin-top: -10px;
}

.checkmarg {
  margin-left: 50px;
}

.mb-3 {
  margin-bottom: 1rem;
}

.normdsfdfalp {
  display: flex;
}

.teamH{
  font-size: 18px;
  color: #6C757D;
  font-weight: 700;
}


@media (max-width: 992px) {
  .formSection {
      padding-top: 110px;
  }

  .userSide{
      margin-right: 2%;
      text-align: right;
  
  }
  .userSide h3{
      margin-right: -2px;
  }

  .chartBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 45px;
  }
  .chartBoxx {
      width: 82%;
      flex-direction: column;
      margin-left: 45px;
  }
  .chartBoxx1 {
      width: 75%;
      flex-direction: column;
      margin-left: 45px;
  }
}
