body {
    background-color: rgba(172, 231, 199, 0.05) !important;

}
*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --Black: #343A40;
    --Background:rgba(172, 231, 199, 0.05);
}


/* AddTeam Styling */
.textMain {
    display: flex;
    gap: 200px;
    /* padding-left: 350px; */
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 100px 10px 100px;
    width: 100%;
}

.textMainds {
    display: flex;
    gap: 98px;
    margin-bottom: 20px;
    /* margin-left: 50px; */
}

.formSection23fa45 {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    background-color: #ACE7C726;
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.formSection23fa45ds {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    /* background-color: #ACE7C726; */
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 20px;
    justify-content: center;

}

.normdfalp {
    /* width: auto; */
    height: auto;
    /* width: 100%; */
    /* background-color: #E9ECEF; */
    /* overflow-y: scroll; */
    text-align: center;
    align-items: center;
    align-content: center;
    /* height: 100vh; */
}

.formSection23fa45 p{
    font-size: 10px;
}

.formSection23fa45 h5{
    font-size: 20px;
    font-weight: 900;
    margin: 0;
    color: #000000;

}

.formSection23fa45ds p{
    font-size: 10px;
}

.formSection23fa45ds h5{
    font-size: 20px;
    font-weight: 800;
    margin: 0;
    color: #2D995F;

}


/* Registration Complete Styling */
.maincont1234 {
    /* width: auto; */
    /* display: flex; */
    /* overflow-y: hidden; */
    /* overflow: hidden; */
    /* height: auto; */
}

.sideNavContainer{
    /* background-color: var(--Deep-green); */
    height: 100vh;
    width: 20%;
    padding-left: 50px;
    overflow: hidden;
    background-image: url('./TeamMemberSideImg.svg');
    background-size: cover;
    /* background-size: contain; */
    /* padding: 31px 64px ; */
    background-repeat: repeat-y;
}

.sideNavContainer h4{
    font-size: 35px;
    color: white;
    font-weight: 800;
    margin-top: 430px;
}

.sideNavContainer p{
    font-size: 20px;
    color: white;
}

.mobileshow {
    height: 880px;
    /* width: 610px; */
}
.formSon2345 {
    text-align: left;
}

.formSection{
    /* width: 100%; */
    /* background-color: #E9ECEF;
    overflow-y: scroll; */
    text-align: center;
    align-items: center;
    align-content: center;
    /* height: 100vh;
    overflow: hidden; */

}

.normalp{
    /* width: 65%; */
    padding: 80px;
    /* padding-left: 50px;
    padding-right: 200px;
    padding-top: 80px; */
    /* padding-left: 170px; */
    /* background-color: #E9ECEF; */
    /* padding-right: 3rem; */
    overflow-y: hidden;
    text-align: center;
    align-items: center;
    align-content: center;
    height: 100vh;

}
.Imageshow213 {
    height: 180px;
    width: 280px;
}

.Imageshowdfsf213 {
    /* height: 50px;
    width: 130px; */
}

.Imageshowda213 {
    /* height: 180px; */
    width: 400px;
    margin-bottom: 20px;
    margin-top: 20px;

    /* padding: 0; */
}

.snupbtn {
    color: #2D995F;
    /* color: white; */
    /* width: 100%; */
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    /* padding: 10px; */;
    background-color: #ffffff;
    border: none;
  }

  .snupbtndfa {
    background-color: #ffffff;
    color: #2D995F;
    width: 200px;
    margin-top: 50px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 10px;
    border: 1px solid #2D995F;
  }

  .snupbtnreddfa {
    background-color: #2D995F;
    color: #ffffff;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 10px;
    border: 1px solid #2D995F;
  }

.formSection2345 {
    padding: 20px 130px 40px 130px;
    /* background-color: white; */
    border-radius: 20px;
    text-align: center;
    /* width: auto; */
    /* height: auto; */
    /* overflow-y: hidden; */
    margin: 0 150px 50px 150px;
}

.formSection2345 h4 {
    font-weight: 700;
    font-size: 18px;
    color: #6C757D;
}
.formSection2345d {
    padding: 10px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    width: auto;
    height: auto;
    overflow-y: hidden;
    margin: 0 200px 50px 200px;
}

.formSection2345d h4 {
    font-weight: 800;
    font-size: 25px;
}

.formSection2345ds {
    padding: 20px 0 10px 0;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    width: auto;
    height: auto;
    overflow-y: hidden;
    margin: 0 200px 0 200px;
}

.formSection2345ds h4 {
    font-weight: 800;
    font-size: 25px;
}

.checkmarg {
    margin-left: 20px;
}

.normdsfdfalp {
    display: flex;
}


@media (max-width: 992px) {
    .formSection {
        padding-top: 110px;
    }

    .userSide{
        margin-right: 2%;
        text-align: right;
    
    }
    .userSide h3{
        margin-right: -2px;
    }

    .chartBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 45px;
    }
    .chartBoxx {
        width: 82%;
        flex-direction: column;
        margin-left: 45px;
    }
    .chartBoxx1 {
        width: 75%;
        flex-direction: column;
        margin-left: 45px;
    }
}