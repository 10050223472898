*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.productContainer {
  /* position: relative; */
}

.productImage {
  /* position: absolute; */
  /* top: 20px;
  right: 50px; */
  width: 120px; /* Adjust the width */
  height: 150px;
  object-fit: contain; /* Ensure the image maintains its aspect ratio */
}

/* colors */
:root {
  --Deep-green: #164b2e;
  --Light-green: #2d995f;
  --Gray: #f2f3f3;
  --White: #ffffff;
  --Black: #343a40;
}
.fetchedTxt {
  font-size: 16px;
  font-weight: 500;
}
.buttonsGroup {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  margin-left: 10px;
  margin-top: 20px;
}

.butt {
  width: 200px;
  height: 40px;
}

/*  */
.formLabelCustom {
  width: 150px;
  text-align: right !important;
  /* white-space: wrap; */
  /* padding-right: 10px; */
}

.formLabeldadCustom {
  width: 150px;
  text-align: right !important;
  /* white-space: wrap; */
  /* padding-right: 10px; */
  margin-left: -15px;
  padding: 0;
}

.formLabddsadelCustom {
  width: 150px;
  text-align: right !important;
  white-space: wrap;
  /* padding-right: 10px; */
  margin-left: -15px;
  padding: 0;
}

.formLabelCustomsdf {
  width: 150px !important;

  text-align: right !important;
  white-space: wrap !important;
  /* padding-right: 10px; */
  /* margin-left: -30px; */
}
.align112234 {
  /* margin-left: -20px; */
}
.align123 {
  /* margin-left: -14px; */
}
.removeMargin {
  margin-top: -10px;
}

.removeMarginadf {
  margin-top: -5px;
}
.row {
  margin-left: 12px;
  margin-top: -10px;
}
.alignRight {
  text-align: right; /* Align input text to the right */
}

.formControl::placeholder {
  text-align: right; /* Align placeholder text to the right */
  /* height: 10px !important; */
}

.form-group-custom {
  /* margin-bottom: 15px; Add margin between form groups */
}

.formCsdfontrol {
  width: 84%; /* Ensure input and select elements take the full width of their parent */
  height: 34px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 20px;
}

.formControl {
  width: 100%; /* Ensure input and select elements take the full width of their parent */
  height: 34px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 15px;
}

.form124Control {
  width: 84%; /* Ensure input and select elements take the full width of their parent */
  height: 34px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 20px;
}
.form124Control1 {
  width: 94%; /* Ensure input and select elements take the full width of their parent */
  height: 38px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 15px;
}
.form124Control12 {
  width: 86%; /* Ensure input and select elements take the full width of their parent */
  height: 38px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 25px;
}

.formSelect {
  width: 190; /* Ensure input and select elements take the full width of their parent */
  height: 34px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: 15px;
}

.formSelesdct {
  height: 34px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  /* margin-left: 15px; */
  width: 100;
}

/*  */
.finishedbodyCont {
  width: 100%;
  padding: 0 5rem;
  margin-top: 5rem;
  /* padding-right: 4rem; */
}
.productText {
  font-weight: 700;
  margin-left: 15rem;
}
.productText1 {
  font-weight: 700;
}
.mainTable table td {
  padding: 0 0 0 5px;
  align-items: center;

  /* display: none; */
}

.endded {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.topPadding {
  width: 100%;
  padding-top: 1rem;
}
.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSecCont h3 {
  color: #343a40;
  font-size: 20px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12rem;
}
.loandethead1 {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}
.laondetheadTopCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-top: 1rem;
}
.laondetheadTopCards .card {
  border: 1px solid var(--Light-green);
  padding: 14px 20px 20px 17px;
  text-align: left;
}
.laondetheadTopCards .card h4 {
  color: var(--Light-green);
  font-weight: 800;
  margin-top: 2px;
  /* font-size:20px; */
}
.loandethead .formIntBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}
.laondetheadTopCards .card p {
  margin-bottom: 0;
}

.formIntBtn .btn1 {
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}

.btndf1 {
  background-color: var(--White) !important;
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black) !important;
}
.formIntBtn .btn1:hover {
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}
.formIntBtn .btn2 {
  font-size: 14px;
  margin-right: 10px;
}

/* .loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Light-green);
  font-size: 16px;
  gap: 5px;
} */
.loandethead button:hover {
  background-color: var(--Light-green);
  color: var(--White);
}

.loanText {
  font-size: 15px;
}
.loanText1 {
  font-size: 15px;
  margin-left: 20px;
}
.loanText2 {
  font-size: 15px;
  margin-left: 25px;
}

.fetchText {
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount {
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText {
  font-size: 18px;
  font-weight: 700;
  color: rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loandgrantcards {
  margin-bottom: 30px;
  margin-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.loanContainer {
  margin-top: 15px;
  overflow-x: auto;
}

.loanTable {
  color: black;
  background-color: rgba(233, 236, 239, 1);
  height: 50px;
  font-weight: normal;
  white-space: nowrap;
  /* padding: 30px; */
  text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th {
  background-color: var(--Light-green);
  color: white;
  /* font-size: 14px; */
}
.loanResponsive table td {
  font-size: 14px;
  border: none !important;
}
.loanResponsive table > td,
th {
  /* border: none; */
  /* font-size: 12px; */
}
.loanResponsive .edit {
  color: var(--Light-green);
  cursor: pointer;
  text-align: center;
}
.loanResponsive .accrodBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Light-green);
}
.accrodBtns p {
  margin: 0;
  font-size: 18px;
}
.loanResponsive .accrodBtns .prev {
  border: 1px solid #2d995f;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
}
.loanResponsive .accrodBtns .next {
  padding: 5px 20px;
}
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: "inter";
  color: #2d995f;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color: rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan {
  width: 300px;
  height: 55px;
  background-color: #2d995f;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover {
  background-color: #46a874;
}

.continueReg {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.regContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  /* gap: 30px; */
}
.sideNav {
  background-color: var(--Deep-green);
  padding: 37px 0 0 21px;
  height: 1800px;
  max-width: 339px;
}
.regMenu {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.regMenu a {
  text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p {
  color: var(--White);
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.regMenu .active {
  background: linear-gradient9(#2d997f) (#2d997f) 0;
}
.formSection {
  padding: 35px 30px;
}
.formSection p {
  font-size: 14px;
}
.formSection h1 {
  color: var(--Light-green);
  font-size: 28px;
  font-weight: 700;
}
.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
}
.formSecCont h3 {
  color: #343a40;
  font-size: 20px;
  font-weight: 700;
}

.mainform {
  width: 100%;
  background-color: var(--White);
  /* border-radius: 12px; */
  /* margin-top: 5rem; */
  /* padding: 0 5rem; */
  display: flex;
  flex-direction: column;
}
.actionsBtns button {
  border-radius: 0;
  font-size: 14px;
}
.diffbtn {
  width: 145px !important;
  height: 35px;
}

@media (max-width: 800px) {
  .finishedbodyCont {
    padding-left: 0;
    padding-right: 0;
  }
  .formSecCont {
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
  }
  .formSecCont h3 {
    color: #343a40;
    font-size: 20px;
    font-weight: lighter;
  }
  .formLabelCustom {
    text-align: left !important;
  }
  .formLabelCustomsdf {
    margin-left: 0;
    text-align: left !important;
  }
  .removeMargin {
    margin-top: 0;
  }
  .align1234 {
    flex-direction: column;
    margin-left: 0;
  }
  .align123 {
    flex-direction: column;
    margin-left: 0;
  }
  .row {
    margin-left: 0;
    margin-top: 0;
  }
  .buttonsGroup {
    justify-content: center;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  .loandethead1 {
    flex-direction: column;
    gap: 20px;
  }
  .laondetheadTopCards .card {
    padding: 12px 30px 15px 13px;
  }
  .finishedbodyCont h3 {
    text-align: center;
  }
  .finishedbodyCont p {
    text-align: center;
  }
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan {
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }

  .continueReg {
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
    /* text-align: center; */
  }
}

.btndivStyle {
  display: flex;
  gap: 20px;
}

.btnStyle {
  padding: 20px 0 0 0;
  margin-bottom: 20px;
}

.finishedbodyCont {
  width: 100%;
  padding: 0 5rem;
  /* padding-right: 4rem; */
}

@media (max-width: 800px) {
  .finishedbodyCont {
    padding-left: 0;
    padding-right: 0;
  }
  .formSecCont {
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
  }
  .formSecCont h3 {
    color: #343a40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }

  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan {
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }

  .continueReg {
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
    /* text-align: center; */
  }

  .btnStyle {
    display: flex;
    flex-direction: column;
  }

  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }

  .finishedbodyCont {
    padding-top: 110px;
  }

  .btndivStyle {
    display: inline-block;
    gap: 0px;
  }

  .show {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .newWidth {
    padding: 0;
  }
}

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}
