*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
}
.fetchedTxt{
  font-size: 16px;
  font-weight: 500;
}
.buttonsGroup{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-left: 50px; */
  /* margin-top: 10px; */
}
.bttn{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px
}
.butt{
  width: 200px;
  height: 40px;
}

/*  */
.formLabelCustom {
  width: 150px; /* Set a fixed width to prevent wrapping */
  text-align: right !important;
  white-space: nowrap;
  padding-right: 15px; /* Add padding to create space between label and input */
}

.alignRight {
  text-align: right; /* Align input text to the right */
}

.formControl::placeholder {
  text-align: right; /* Align placeholder text to the right */
}

.form-group-custom {
  /* margin-bottom: 15px; Add margin between form groups */
}

.formControl,
.formSelect {
  width: 100%; /* Ensure input and select elements take the full width of their parent */
  height: 38px; /* Set a fixed height for consistency */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/*  */
.finishedbodyCont{
  width: 100%;
  padding: 0 7rem;
  margin-top: 5rem;
  /* padding-right: 4rem; */


}
.productText{
  font-weight: 700;
  font-size: 30px;
  /* margin-left: 15rem; */
}
.productText1{
  font-weight: 700;
}
.mainTable table td{
  padding: 2px 2px 2px 5px;
align-items: center;

  /* display: none; */
}


.endded {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.topPadding{
  width: 100%;
  padding-top: 1rem;
}

.newWidthadf {
  margin: 0 5rem 0 5rem
}

.formSecCont{
  padding: 24px 32px;
  /* margin-top: 10px; */
  background-color: var(--White);
  /* border-radius: 12px; */
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formSsdsdecCont{
  padding: 0 24px 0 32px;
  margin-top: 50px;
  /* background-color: var(--White); */
  /* border-radius: 12px; */
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formSsdsdecCont h3{
  color: #343A40;
  font-size: 30px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12rem;
}
.loandethead1{
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.loandethead .formIntBtn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}

.formIntBtn .btn1{
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn1:hover{
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}
.formIntBtn .btn2{
  font-size: 14px;
}

/* .loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Light-green);
  font-size: 16px;
  gap: 5px;
} */
.loandethead button:hover{
  background-color: var(--Light-green);
  color: var(--White);
}

.loanText {
  font-size: 15px;
}
.loanText1 {
  font-size: 15px;
  margin-left: 20px;
}
.loanText2 {
  font-size: 15px;
  margin-left: 25px;
}

.fetchText{
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount{
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText{
  font-size: 18px;
  font-weight: 700;
  color:rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loandgrantcards{
  margin-bottom: 30px;
  margin-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.loanContainer{
  margin-top: 15px;
  overflow-x: auto;
}

.loanTable {
 color: black;
 background-color: rgba(233, 236, 239, 1);
 height: 50px;
 font-weight: normal;
 white-space: nowrap;
 /* padding: 30px; */
 text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th{
  background-color: var(--Light-green);
  color: white;
  /* font-size: 14px; */
}
.loanResponsive table td{
  font-size: 14px;
  border: none !important;
}
.loanResponsive table > td, th{
  /* border: none; */
  /* font-size: 12px; */
}
 .loanResponsive .edit{
    color: var(--Light-green);
    cursor: pointer;
    text-align: center;
 }
 .loanResponsive .accrodBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--Light-green);
 }
 .accrodBtns p{
  margin: 0;
  font-size: 18px;
 }
 .loanResponsive .accrodBtns .prev{
  border: 1px solid #2D995F;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
 }
 .loanResponsive .accrodBtns .next{
    padding: 5px 20px;
 }
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: 'inter';
  color: #2D995F;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color:rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan{
  width: 300px;
  height: 55px;
  background-color: #2D995F;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover{
  background-color: #46a874;
}

.continueReg{
  color: white;
  font-size: 16px;
  font-weight: 700;
text-align: center;


}

.regContainer{
    display: grid;
    grid-template-columns: 25% 75%;
    /* gap: 30px; */
}
.sideNav{
    background-color: var(--Deep-green);
    padding: 37px 0 0 21px;
    height: 1800px;
    max-width: 339px;
}
.regMenu{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.regMenu a{
    text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p{
    color: var(--White);
    font-size: 18px;
    font-weight: 400;
    margin:0;
}
.regMenu .active{
    background: linear-gradient9(#2D997F) (#2D997F)0;
}
.formSection{
    padding: 35px 30px;
}
.formSection p{
    font-size: 14px;
}
.formSection h1{
    color: var(--Light-green);
    font-size: 28px;
    font-weight: 700;
}

.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
}

.mainform{
    width: 100%;
    background-color: var(--White);
    /* border-radius: 12px; */
    /* margin-top: 7rem; */
    display: flex;
    flex-direction: column;
}
.actionsBtns button{
  border-radius:0;
  font-size: 14px;
}
.diffbtn {
  width: 145px !important;
  height: 35px;
}

@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }
}

.btndivStyle {
  display: flex; 
  gap: 20px;
}

.btnStyle {
  padding: 20px 0 0 0 ;
  margin-bottom: 20px;
}

.finishedbodyCont{
  /* width: 100%;
  padding: 0 2rem 0 2rem; */
  /* padding-right: 4rem; */
}

@media(max-width:800px){
  
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }

  .btnStyle {
    display: flex;
    flex-direction: column;
  }

  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }

  /* .finishedbodyCont {
    padding-top: 110px;
  } */

  .btndivStyle {
    display: inline-block; 
    gap: 0px;
  }

  .show {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .newWidth{
    padding: 0;
  }
}
  
  
