body {
    background-color: rgba(172, 231, 199, 0.05) !important;

}
*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --Black: #343A40;
    --Background:rgba(172, 231, 199, 0.05);
}


/* AddTeam Styling */
.textMain {
    display: flex;
    text-align: center;
    margin: 5px 0 10px 0;
    justify-content: center;
    /* background-color: red; */
}

.formSection23d{
  font-weight: 800;
  font-size: 25px;
}

.textMainds {
    display: flex;
    gap: 98px;
    margin-bottom: 20px;
    /* margin-left: 50px; */
}

  .snupbtnreddfa {
    background-color: #fd0000;
    color: #ffffff;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 10px;
    border: 1px solid #fd0000;
  }

.formSection23fa45 {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    background-color: #ACE7C726;
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
}

.normdfalp {
    /* width: auto; */
    /* height: auto; */
    /* width: 100%; */
    /* background-color: #E9ECEF; */
    /* overflow-y: scroll; */
    text-align: center;
    align-items: center;
    align-content: center;
    /* height: 100vh; */
}

.formSection23fa45 p{
    font-size: 10px;
}

.formSection23fa45 h5{
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    color: #2D995F;

}


/* Registration Complete Styling */
.maincont1234 {
    /* width: 100%; */
    /* display: flex; */
    /* overflow-y: hidden; */
    /* height: 100vh; */
    /* background-color: white; */
}

.sideNavContainer{
    /* background-color: var(--Deep-green); */
    height: 100vh;
    width: 35%;
    padding-left: 50px;
    overflow: hidden;
    background-image: url('./TeamMemberSideImg.svg');
    background-size: cover;
    /* background-size: contain; */
    /* padding: 31px 64px ; */
    background-repeat: repeat-y;
}

.sideNavContainer h4{
    font-size: 35px;
    color: white;
    font-weight: 800;
    margin-top: 430px;
}

.sideNavContainer p{
    font-size: 20px;
    color: white;
}

.mobileshow {
    height: 880px;
    /* width: 610px; */
}

.formSon2345 {
    text-align: left;
    margin-top: 20px;
}

.formSection{
    /* width: 100%; */
    /* background-color: #E9ECEF; */
    /* overflow-y: scroll; */
    text-align: center;
    align-items: center;
    align-content: center;
    /* height: 100vh; */
    /* padding:0 10rem; */

}

.normalp{
    /* width: 65%; */
    padding: 80px;
    /* padding-left: 50px;
    padding-right: 200px;
    padding-top: 80px; */
    /* padding-left: 170px; */
    /* background-color: #E9ECEF; */
    /* padding-right: 3rem; */
    overflow-y: hidden;
    text-align: center;
    align-items: center;
    align-content: center;
    height: 100vh;

}
.Imageshow213 {
    height: 180px;
    width: 280px;
}

.back{
  display:flex;
  align-items: right ;
  justify-content:right;
  text-align: right;
  /* width:100% */
}

.backDiv{
  padding-right: 30px;
  display: block;
  /* background-color: red; */
  text-align: right;
  display: flex;
  align-items:flex-end;
  justify-content: right;
}


.Imageshowdfsf213 {
    /* height: 50px;
    width: 130px; */
}

.Imageshowda213 {
    /* height: 180px; */
    width: 400px;
    margin-bottom: 20px;
    margin-top: 20px;

    /* padding: 0; */
}

.snupbtn {
    background-color: #2D995F;
    color: white;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 10px;
  }

  .snupdrgtbtn {
    background-color: #ffffff;
    color: #2D995F;
    /* width: 13%; */
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 5px;
    /* border: 1px solid #fd0000; */
    display: flex;
    /* margin-left: 50%; */
    /* position: absolute; */
  }

  .snupdfdfbtn {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 5px;
    border: 1px solid #fd0000;
    display: flex;
    /* margin-left: 50%; */
    /* position: absolute; */
  }

  .buttondivddsadf {
    width: 100%;
    /* background-color: red; */
    height: 40px;
    /* justify-content: left; */
    /* align-items: left; */
    text-align: left;
    display: flex;
    justify-content: space-between;
    /* align-content: left; */
    /* justify-items: left; */
  }

  .snupbtndfa {
    background-color: #ffffff;
    color: #2D995F;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 10px;
    border: 1px solid #2D995F;
  }

.formSection2345 {
    padding: 20px 50px 30px 50px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    width: auto;
    height: auto;
    overflow-y: hidden;
    margin: 0 80px 50px 80px;
}

.formSection2345 h4 {
    font-weight: 800;
    font-size: 25px;
}

.checkmarg {
    margin-left: 20px;
}

.mb-3 {
    margin-bottom: 1rem;
}

.normdsfdfalp {
    display: flex;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* margin-top:-5rem; */
    /* margin-bottom:10px */
  }
  .labelSelect{
    text-align: left;
    margin-bottom: 10px;
  }
  
  .card {
    /* text-align: center; */
    /* background: #fff; */
    /* padding: 40px 60px 0 40px; */
    border-radius: 8px;
    /* border: 1px solid #CED4DA; */
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05); */
    /* width: 90%; */
    width: 700px !important;
    height: 200px !important;
    padding: 60px !important;
    /* padding-bottom: 30px !important; */
  }

  .newHed{
    text-align: left;
    color: #2D995F;
    font-weight: 400;
    font-size: 16px;
  }
  
  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #6C757D;
  }
  
  .subtitle {
    font-size: 16px;
    color: #6b7280;
    /* margin-bottom: 32px; */
  }
  
  .label {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 8px;
    text-align: left;
    margin-top: 20px;
  }
  
  .dropdown {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #6C757D;
    border-radius: 8px;
    background-color: #ffffff;
    color: #343A40;
    margin-bottom: 24px;
  }
  
  .skips{
    text-align: right;
  }
  .dropdown:focus {
    outline: none;
    border-color: #10b981;
    box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.2);
  }
  
  .continueButton {
    width: 200px;
    padding: 12px;
    margin-top: 50px;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    background-color: #2D995F;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    transition: background-color 0.3s;
  }
  
  .continueButton:hover {
    background-color: #059669;
  }
  
  .backButton {
    font-size: 16px;
    font-weight: 400;
    color:#6C757D;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .skipButton {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #6b7280;
    background: none;
    border: none;
    cursor: pointer;
  }
  


@media (max-width: 992px) {
    .formSection {
        padding-top: 110px;
    }

    .userSide{
        margin-right: 2%;
        text-align: right;
    
    }
    .userSide h3{
        margin-right: -2px;
    }

    .chartBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 45px;
    }
    .chartBoxx {
        width: 82%;
        flex-direction: column;
        margin-left: 45px;
    }
    .chartBoxx1 {
        width: 75%;
        flex-direction: column;
        margin-left: 45px;
    }
}
