

.invoiceContainer {
  color: #333;
  width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}

.receipt {
  width: 408px;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: white;
  padding-top: 44px;
  padding-left: 37px;
  padding-right: 37px;
  padding-bottom: 37px;
  margin-top: 10px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #343a40;
}

.address,
.contact {
  font-size: 12px;
  margin: 2px 0;
  font-weight: 400;
  color: #6c757d;
  text-align: center;
  width: 230px;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  border-top: 1px dashed #adb5bd;
  margin: 10px 0;
}

.receiptTitle {
  font-size: 20px;
  font-weight: 400;
  margin: 5px 0;
  color: #343a40;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.table th,
.table td {
  text-align: left;
  font-size: 12px;
  padding: 5px;
}

.descriptionHeader,
.qtyHeader,
.amountHeader {
  font-weight: 700;
  font-size: 14px;
  color: #6c757d;
}
.description,
.qty,
.amount {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
}

.cashScetion {
  display: flex;
  justify-content: space-between;
}
.totalSection {
  display: flex;
  justify-content: space-between;
  margin: 0px 0;
}

.totalLabel {
  font-size: 14px;
  font-weight: 700;
  color: #6c757d;
}
.totalLabel,
.cash {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
}

.totalAmount,
.totalCash {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
}
.totalAmount {
  font-size: 14px;
  font-weight: 700;
  color: #6c757d;
}
.totalAmount1 {
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
}

.thankYou {
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  color: #343A40;
}

.barcode {
  height: 50px;
  background: repeating-linear-gradient(
    90deg,
    #000,
    #000 2px,
    #fff 2px,
    #fff 4px
  );
  margin: 10px auto;
  width: 80%;
}
