*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.planContainer {
  display: flex;
  flex-direction: row !important;
  /* align-items: center;
  justify-content: center; */
  margin: 0 10px 0 0;
  /* gap: 30px; */
  /* background-color: red; */
}


.container {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  /* min-height: 100vh;      */
  padding: 0 5rem 2rem 5rem;
  /* width: 100px; */
}

.accText {
  font-weight: 700;
  color: #071437;
  font-size: 20px;
  margin-bottom: 0px;
}

.infoText {
  color: #99a1b7;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 32px;
}

.infoText > span {
  color: #2d995f;
}

.specify {
  margin-bottom: 3px;
  font-size: 12px;
}

.sizeDiv {
  display: flex;
  gap: 20px;
  align-items: center;
}
.size {
  border: 1px dotted #1b84ff;
  padding: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  color: #071437;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
}

.srt {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}

.pricingDesg {
  /* background-color: var(--white); */
  /* position: relative; */
  /* padding: 32px 34px; */
  /* height: 620px; */
  /* width: 350px; */
  /* border-radius: 20px; */
  /* margin: 10px 0 5px 0; */
  /* padding-bottom: 20px; */
  /* box-shadow:2px 2px 5px rgba(5, 5, 5, 0.15); */
}

.containerForPricing {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-bottom: 3rem;

}

.pricingPad h4 {
  font-size: 24px;
  /* color: #343A40; */
  font-weight: 700;
}
.pricingPad2 h4 {
  font-size: 24px;
  color: var(--white);  
  font-weight: 700;
}
.pricingPad2 p{
  color: var(--white) !important;
} 
.pricingDesg2 button{
  background-color: var(--white);
  color: var(--green);
  transition: ease 0.5px;
}
.pricingDesg2 button:hover{
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.freePlan2 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}

.amount {
  font-size: 30px;
  font-weight: 700;
  color: #6C757D;
  margin-top: 39px;
}

.amountSpan {
  font-size: 14px;
  font-weight: 400;
}
.useFor {
  font-size: 14px;
  font-weight: 400;
  color: #6C757D;
  margin: 15px 0 26px 0;
}
.lists {
  /* padding-left: 5px; */
  color: #6C757D;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line if items overflow */
  gap: 16px;
}
.subLists{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  gap: 20px;
  padding: 9px 0;
  gap: 8px;
}
.subLists p{
  font-size: 14px;
  font-weight: 400;
}

.tick {
  width: 25px;
  height: 25px;
  /* padding-right: 20px; */
  /* margin-top: 10px; */
}




.acctName {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.teamName {
  font-weight: 500;
  color: #071437;
  font-size: 14px;
}

.inputAcct {
  background-color: #f9f9f9;
  color: #4b5675;
  border-radius: 8px;
  min-height: 40px;
  border: 1px solid #f9f9f9;
  margin-top: 3px;
  width: 75%;
  padding-left: 8px;
  margin-bottom: 32px;
}

.Aplan {
  width: 75%;
}

.selectOne {
  display: flex;
  align-items: center;
  gap: 16px;
}

.TopOne {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cpTxt {
  margin-bottom: 0;
}

.buttonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  
}

/* .continue {
  width: 377px;
  height: 43px;
} */

.back {
  background-color: #e9f3ff;
  color: #1b84ff;
  border-radius: 8px;
  padding: 12px 24px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.continue {
  border-radius: 0;
  background-color: #2d995f;
  color: #ffffff;
  gap: 10px;
  /* padding: 12px 24px; */
 text-align: center;
 font-size: 16px;
  width: 377px;
  height: 43px;
}
