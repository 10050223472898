.topTopNav{
    width: 100%;
    background-color: #ffffff;
    min-height: 85px;
    position: fixed;
    top:0;
    z-index: 1000;
    /* margin-bottom: 1500px; */
}

.top2{
    padding:15px 27px 12px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.secondSTyle{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:17px;
}

.home{
    font-size: 20px;
    font-weight: 500;
    color:#2D995F;
}

.homeDiv{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

p {
    margin-bottom: 0;
}
.homeDiv > a{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.Dropdowns{
    background-color: transparent !important;
    border: none !important;
    /* color: black !important; */
    /* padding: 15px; */
    /* width: 100%; */
    height: 25px;
    min-width: 60px;
    /* background-color: red !important; */
    transform: translateY(-30px);
    /* transform: translateX(-20px); */
}

.selectIpt{
    position: absolute;
    transform: translateY(40px);
    transform: translateX(-60px);
    height:58px;
    border-radius: 50px;
    opacity: 0;
    padding: 5px;
    padding-right: 0px;
    cursor: pointer;
    
}

.homeDiv img{
   height:35px;
   width: 35px;
}

.styledDiv{
    padding-top: 10px;
    border-right: 2px solid #227247;
    padding-right: 20px;
}

.company{
    font-size: 12px;
    font-weight: 400;
    color:#000000;
    text-align: end;
}

.companyName{
    font-size: 20px;
    font-weight: 700;
    color:#000000;
    text-align: right;
}
.companyName1{
    font-size: 16px;
    /* font-weight: 500; */
    color:#000000;
    text-align: right;
}


@media(max-width:900px){
    .companyName{
        font-size: 15px;
        font-weight: 700;
        color:#000000;
        text-align: right;
    }
}