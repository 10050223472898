.layout {
    background-color: #ffffff;
    height: 100vh;
    width: 100%;
    /* background-repeat: no-repeat;
    background-position: center; */
}
.main {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    /* gap: 40px; */
}

.mainpic {
    /* width: 300px;
    height: 249px; */
    margin-top: -90px;
    /* top: 168px;
    left: 500px;
    gap: 0px;
    opacity: 0px; */
}
.pic3 {
    margin-top: 5px;
    margin-left: 9rem;
}

.ballon {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding:  20px; */
    /* display: flex;  */
    /* flex-direction: row; */
    /* gap: 20rem; */
}
.successText{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ballon2{
    padding: 40px;
  /* padding-top: 3rem; */
}
.ballon1{
    margin-top: -120px;
    /* display: flex;
    align-self: flex-start;
    justify-self: start;
    padding-top: -1rem; */
}

.ballon3 {
    padding: 40px;
    /* display: flex; */
    /* align-self: flex-end;
    justify-self: end;
    margin-right: 18rem; */
}

.layouts {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;  
    margin-top: 30px;
}

.snupbtn1 {
    /* display: inline-flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;  */
    /* border: 1px solid #2D995F; */
    color: #ffffff;
  background-color: #2D995F;
    width: 300px;
    height: 43px;
    /* margin: 0 auto; */
    /* margin: 1rem 12rem 0rem 29rem; */
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 10px;
    border-radius: 0;
  }
/* .header{ */
    /* display: flex; */
    /* width: 100%; 
     height: 100vh;
    background-color: #F8FBFF; */
    
    /* flex-direction: column; */
/* } */
/* 
.pic{
    height:300px;
    width: 100%; */
    /* object-fit: contain; */
  /* } */

/* .card{
    background-color:white;
    width: 100%;
    max-width: 70%; */
   
    /* margin: auto; */
    /* position: absolute;
    top: 240px;
    left: 170px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* height: 70%; */
    /* border-radius: 20px;
    box-shadow: 0px 4px 30px 20px #F2F2F221;
    padding-top: 40px;
    margin-bottom: 90px;
  }  */
  
