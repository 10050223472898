*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.top {
    background-color: #2D995F;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-left: 30px;
    margin-right: 30px;    
    height: 272px;
    /* margin-top: 30px; */
    gap: 0px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1 !important;
    margin-top: 11%;
    background-image: url(../../assets/welc/Vector\ 1.png);
    /* width: 100%; */
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

}

.fontChange{
    color: #2D995F;
    font-weight: 700; 
    font-size: 16px;
}
.fontChange:hover{
    color: #144e2f;
}

.top img {
    width: 355px;
    height: 304.99px;
    right: -12px;
    gap: 0px;
    opacity: 1 !important;
    
}

.text {
    color: #ffffff;
    margin-left: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px; 
}

.text h6, .text h3, .text p {
    margin: 0;
    opacity: 1; 
}

.text h6 {
    width: 102px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    text-align: left;
    color: #ffffff;
}

.text h3 {
    width: 500px;
    font-size: 45px;
    font-weight: 800;
    line-height: 58px; 
    text-align: left;
    color: #ffffff;
}



.text p {
    width: 398px;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #ffffff;
}

/* Tabs section styling */
.tabs {
    display: flex;
    flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
    flex-direction: column;
    gap: 20px; /* Add space between tabs */
    padding: 20px; /* Add padding around the tabs section */
    margin-top: 30px; /* Add space from the top section */
}
.general{
    display: flex;
    flex-direction: row;
    gap: 5%;
}
a{
    text-decoration: none;
    color: black;
    font-size: 15px;
}
.tabs2 {
    display: flex;
    flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
    flex-direction: column;
    gap: 20px; /* Add space between tabs */
    padding: 20px; /* Add padding around the tabs section */
    margin-top: 30px; /* Add space from the top section */
}
.tabs3 {
    display: flex;
    flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
    flex-direction: column;
    gap: 20px; /* Add space between tabs */
    padding: 20px; /* Add padding around the tabs section */
    margin-top: 30px; /* Add space from the top section */
}
.first {
    cursor: pointer;
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Add shadow for depth */
    width: 100%; /* Ensure the tab takes full width */
    max-width: 400px; /* Set a max-width for individual tabs */
}

.first img {
    width: 80px; /* Size the image */
    height: auto;
    /* border-radius: 8px; */
    margin-right: 20px; /* Space between image and text */
}
.second {
    cursor: pointer;
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    padding: 20px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Add shadow for depth */
    width: 100%; /* Ensure the tab takes full width */
    max-width: 400px; /* Set a max-width for individual tabs */
}

.second img {
    width: 80px; /* Size the image */
    height: auto;
    /* border-radius: 8px; */
    margin-right: 20px; /* Space between image and text */
}
.third {
    cursor: pointer;
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    padding: 20px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Add shadow for depth */
    width: 100%; /* Ensure the tab takes full width */
    max-width: 400px; /* Set a max-width for individual tabs */
}

.third img {
    width: 80px; /* Size the image */
    height: auto;
    /* border-radius: 8px; */
    margin-right: 20px; /* Space between image and text */
}
.fourth {
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    padding: 20px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Add shadow for depth */
    width: 100%; /* Ensure the tab takes full width */
    max-width: 400px; /* Set a max-width for individual tabs */
}

.fourth img {
    width: 80px; /* Size the image */
    height: auto;
    /* border-radius: 8px; */
    margin-right: 20px; /* Space between image and text */
}
.fifth {
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    padding: 20px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Add shadow for depth */
    width: 100%; /* Ensure the tab takes full width */
    max-width: 400px; /* Set a max-width for individual tabs */
}

.fifth img {
    width: 80px; /* Size the image */
    height: auto;
    /* border-radius: 8px; */
    margin-right: 20px; /* Space between image and text */
}
.tabtext {
    display: flex;
    flex-direction: column;
}

.tabtext p:first-of-type {
    /* font-size: 20px; */
    font-weight: 600;
    margin-bottom: 8px; /* Space between title and text */
}

.tabtext p:last-of-type {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
}

@media(max-width:900px){
    .general{
        display: flex;
        flex-direction: column;
        /* gap: 5%; */
        margin: 0;
        padding: 0;
    }

    .tabs {
        display: flex;
        flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
        flex-direction: column;
        gap: 20px; /* Add space between tabs */
        padding: 20px; 
        margin-top: 0;
    }
    .tabs2 {
        display: flex;
        flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
        flex-direction: column;
        gap: 20px; /* Add space between tabs */
        padding: 20px; /* Add padding around the tabs section */
        margin-top: 0; /* Add space from the top section */
    }
    .tabs3 {
        display: flex;
        flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
        flex-direction: column;
        gap: 20px; /* Add space between tabs */
        padding: 20px; /* Add padding around the tabs section */
        margin-top: 0; /* Add space from the top section */
    }
    .top {
        background-color: #00C7BE;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        margin: 0 30px 100px 30px;
        height: 272px;
        /* margin-top: 30px; */
        gap: 0px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1 !important;
        /* background-image: url(../../assets/welc/Vector\ 1.png); */
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        background-image: none;
  
    }

    .text {
        color: #015c0d;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 300px; 
        /* margin-left: 150px; */
        margin: 30px 0 0 150px;
    }
    
    .text h6, .text h3, .text p {
        margin: 0;
        opacity: 1; 
        padding: 0;
    }
    
    .text h6 {
        width: 102px;
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 15.2px;
        text-align: left;
        color: #2D995F;

    }
    
    .text h3 {
        width: 500px;
        font-size: 30px;
        font-weight: 800;
        line-height: 38px; 
        text-align: left;
        color: #164B2E;
    }  
    
    
    .text p {
        width: 398px;
        font-family: Inter;
        font-size: 10px;
        font-weight: 500;
        line-height: 15.36px;
        text-align: left;
        color: #2D995F;
    }
  }