/* Container for the entire table */
.tableContainer {
    width: 80%;
    overflow-x: auto;
  }
  
  /* Header row styling */
  .headerRow {
    background-color: #333; /* Dark gray background */
    color: #ffffff; /* White text */
    text-align: left;
  }
  
  /* Header cell styling */
  .headerCell {
    padding: 12px;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid #444;
  }
  
  /* Body row styling */
  .bodyRow {
    background-color: #f5f5f5;
  }
  
  .bodyRow:nth-child(even) {
    background-color: #e0e0e0; /* Alternate row color for even rows */
  }
  
  /* Body cell styling */
  .bodyCell {
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    color: #333;
  }
  
  /* Specific styling for cells that need alignment */
  .alignRight {
    text-align: right;
  }
  
  .alignCenter {
    text-align: center;
  }
  
  /* Icon styling for actions */
  .iconButton {
    border: none;
    background: transparent;
    cursor: pointer;
    color: #ff4d4f; /* Red color for delete icon */
  }
  
  .iconButton:hover {
    color: #ff3333;
  }
  