/* Main container styles */
.container {
    /* padding: 20px; */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    /* height: 100vh; */
    /* background-color: #ffffff;  */
    /* padding: 20px; */
    /* padding-top: 0px; */
    /* margin-top: -60px ; */
  }
  
  /* Header section */
  .header {
    font-size: 18px;
    font-weight: 700;
    color: #6C757D; /* Dark text */
    margin-bottom: 10px;
  }
  
  .subheader {
    font-size: 12px;
    font-weight: 400;
    color: #6C757D; /* Gray text */
    margin-bottom: 32px;
  }
  
  /* Card styles */
  .card {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 350px;
    height: 150px;
    border: 1px solid #CED4DA; 
    border-radius: 8px; /* Rounded corners */
    /* background-color: white;  */
    /* margin-bottom: 24px; */
    text-align: center;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);  */
    padding: 12px;
  }
  
  .teamMember{
    font-weight: 700;
    font-size: 16px;
    color: #2D995F;
    margin-top: 1rem;
    cursor: pointer;
  }
  
  /* Icon container */
  .icon {
    width: 50px;
    height: 50px;
    /* background-color: #e9ecef; Light gray */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    /* color: #6c757d; Gray icon color */
    margin-bottom: 12px;
  }
  
  /* Add text inside the card */
  .addText {
    font-size: 14px;
    color:#2D995F;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .addLink {
    font-size: 10px;
    color: #6C757D; /* Green text for link */
    cursor: pointer;
    font-weight: 300;
    text-align: left;
  }
  
  /* Next button */
  .button {
    width: 240px;
    height: 44px;
    background-color: #2D995F; /* Green button */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 6px; /* Slightly rounded corners */
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  
  .button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
